import axios from 'axios';

import SingularModule from './module-templates/singular';

export default SingularModule({
  name: 'subscriptions',
  actions: {
    async checkout(context, interval){
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/subscriptions/checkout-url/${interval}/${context.rootGetters['user/email']}`)
          .then(response => {
            if(response && response.data && response.data.url){
              window.location.href = response.data.url
            }else{
              reject('Unknown Error')
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    pause(context){
      return axios
        .post(`/api/subscriptions/pause`)
        .then(() => {})
        .catch(() => {})
        .finally(() => { context.dispatch('load') })
    },
    resume(context){
      return axios
        .post(`/api/subscriptions/resume`)
        .then(() => {})
        .catch(() => {})
        .finally(() => { context.dispatch('load') })
    },
    cancel(context){
      return axios
        .post(`/api/subscriptions/cancel`)
        .then(() => {})
        .catch(() => {})
        .finally(() => { context.dispatch('load') })
    },
    previewMigrate(context, data){
      return axios
        .post(`/api/subscriptions/preview-migrate`, data)
        .then(() => {})
        .catch(() => {})
        .finally(() => { context.dispatch('load') })
    },
    migrate(context, data){
      return axios
        .post(`/api/subscriptions/migrate`, data)
        .then(() => {})
        .catch(() => {})
        .finally(() => { context.dispatch('load') })
    }
  },
  getters: {
    isSubscribed: state => !!(state.document.id)
  }
});
