import Button from "@/components/atoms/Button";

export default {
    name: 'navigation',
    namespaced: true,
    state: {
        primary: [
            // {
            //     title: 'How it Works',
            //     dropdown: 'product',
            //     element: 'span',
            //     align: 'center',
            //     sectionsDisabled: [
            //         {
            //             title: '',
            //             menu: [
            //                 {
            //                     title: 'Paste a video link',
            //                     description: 'From Youtube, Vimeo, and more',
            //                     icon: 'arrow',
            //                     route: {name: 'Home', hash: '#import'},
            //                     step:1
            //                 },
            //                 {
            //                     title: 'Instantly start chatting',
            //                     description: 'Ask questions, get summaries, pinpoint key points, translate, and more',
            //                     icon: 'film',
            //                     route: {name: 'Home', hash: '#highlight'},
            //                     step:2
            //                 },
            //                 {
            //                     title: 'Add Your Notes',
            //                     description: 'Storytelling, production logistics, cinematography and aesthetics, etc.',
            //                     icon: 'compose',
            //                     route: {name: 'Home', hash: '#take-notes'},
            //                     step:3
            //                 },
            //                 {
            //                     title: 'Share with your clients and team',
            //                     description: 'Storyboards, plans, pitches, reports, spreadsheets, pro files and more.',
            //                     icon: 'link',
            //                     route: {name: 'Home', hash: '#export-and-share'},
            //                     step:4
            //                 }
            //             ]
            //         }
            //     ]
            // },
            // {
            //   title: 'Products',
            //   dropdown: 'products',
            //   element: 'span',
            //   align: 'center',
            //   // attributes: {
            //   //   class: ['my-class1', { 'my-class2': true }],
            //   //   // Custom attributes
            //   //   'data-big': 'yes'
            //   // },
            //   // // add some events?
            //   // listeners: {
            //   //   // all possible native events
            //   //   mouseover: (evt) => {
            //   //     console.log('news hover', evt)
            //   //   }
            //   // },
            //   // // just extra properties in the object
            //   // customAttribute: true,
            //   sections: [
            //     {
            //       title: '',
            //       menu: [
            //         {
            //           title: 'Storyboards & Moodboards',
            //           // title: 'Video-to-Storyboard',
            //           description: 'Instantly turn videos into storyboards and moodboards.',
            //           icon: 'view-grid',
            //           route: ''
            //         },
            //         {
            //           title: 'Pitches & Presentations',
            //           description: 'Generate beautiful, detail-rich presentations that clients love.',
            //           icon: 'view-storyboard',
            //           route: ''
            //         },
            //         {
            //           title: 'Production Planning & Quoting',
            //           description: 'Create easy-to-understand project requirements from any video.',
            //           icon: 'gear',
            //           route: ''
            //         }
            //       ]
            //     },
            //
            //     {
            //       title: 'Other',
            //       menu: [
            //         {
            //           title: 'Learning & Discovery',
            //           // class: 'mini',
            //           description: 'Reverse-engineer videos to study structure, techniques & methods.',
            //           icon: 'graduation-cap',
            //           route: ''
            //         },
            //         {
            //           title: 'Remix & Repurpose',
            //           description: 'Export subtitles, pro media files, spreadsheets and more.',
            //           icon: 'merge',
            //           route: ''
            //         },
            //         {
            //           title: 'Archiving',
            //           // class: 'mini',
            //           description: 'Metadata tagging, spreadsheet exports and more.',
            //           icon: 'tag',
            //           route: ''
            //         }
            //       ]
            //     }
            //   ]
            // },

            // {
            //   title: 'Customers',
            //   dropdown: 'customers',
            //   element: 'span',
            //   align: 'center',
            //   // attributes: {
            //   //   class: ['my-class1', { 'my-class2': true }],
            //   //   // Custom attributes
            //   //   'data-big': 'yes'
            //   // },
            //   // // add some events?
            //   // listeners: {
            //   //   // all possible native events
            //   //   mouseover: (evt) => {
            //   //     console.log('news hover', evt)
            //   //   }
            //   // },
            //   // // just extra properties in the object
            //   // customAttribute: true,
            //   sections: [
            //     {
            //       title: '',
            //       menu: [
            //         {
            //           title: 'Video Production Studios',
            //           description: 'Commercial, Wedding & Corporate',
            //           icon: 'home',
            //           route: {name: 'video-production'}
            //         },
            //         {
            //           title: 'Marketing Agencies',
            //           description: 'Social Media & Advertising',
            //           icon: '4-point-star',
            //           route: {name: 'marketing'}
            //         },
            //         {
            //           title: 'Film & TV',
            //           description: 'Film, TV & Documentary',
            //           icon: 'film',
            //           route: ''
            //         },
            //         {
            //           title: 'Education',
            //           description: 'Students, Educators & Universities',
            //           icon: 'graduation-cap',
            //           route: ''
            //         }
            //       ]
            //     }
            //   ]
            // },
            // {
            //     title: 'Features',
            //     element: 'router-link',
            //     attributes: {
            //         to: {hash: 'features'}
            //     }
            // },
            // {
            //     title: 'Pricing',
            //     element: 'router-link',
            //     attributes: {
            //         to: {hash: 'pricing'}
            //     }
            // },
            // {
            //     title: 'FAQs',
            //     element: 'router-link',
            //     attributes: {
            //         to: {hash: 'faqs'}
            //     }
            // },
            // {
            //     title: 'Support',
            //     element: 'router-link',
            //     attributes: {
            //         to: {hash: 'support'}
            //     }
            // }
        ]
    },
    getters: {
        secondary: (state, getters, rootState, rootGetters) => {
            const isSignedIn = rootGetters['user/isSignedIn'];
            const items = [];
            // if(!isSignedIn){
            //     items.push({
            //         component: 'router-link',
            //         attributes: {
            //             to: {name:'signin'},
            //         },
            //         slot: 'Sign In'
            //     });
            // }
            // items.push({
            //     component: 'a',
            //     attributes: {
            //         href: 'https://blog.storyfolder.com'
            //     },
            //     slot: 'Blog'
            // });

            items.push({
                component: 'a',
                attributes: {
                    href: 'https://chat-with-videos.canny.io/'
                },
                slot: 'Request a Feature'
            });
            if(isSignedIn){
                // items.push({
                //     component: 'router-link',
                //     attributes: {
                //         to: {name:'support'}
                //     },
                //     slot: 'Support',
                // });
                // items.push({
                //     component: 'router-link',
                //     attributes: {
                //         to: {name:'onboard', query: {redirect: 'AccountUserView'}}
                //     },
                //     slot: 'My Account'
                // });
            }
            if(!isSignedIn){
                // items.push({
                //     component: Button,
                //     attributes: {
                //         to: {name: 'signup', query: {redirect: '/welcome'}},
                //         label: 'Create free account'
                //     }
                // });
            }
            return items;
        },
        account: (state, getters, rootState, rootGetters) => {
            if(!rootGetters['user/isSignedIn']){
                return [];
            }
            const isAdmin = rootGetters['user/isAdmin'];
            const subscribed = rootGetters['subscriptions/isSubscribed'];

            const menuItems = [{
                slot: 'Account Overview',
                component: 'router-link',
                attributes: {
                    to: { name: 'AccountUserView' },
                },
                icon: 'home',
                iconClass: ''
            }];

            menuItems.push({
                slot: 'Upgrade',
                component: 'router-link',
                attributes: {
                    to: { name: 'pricing' },
                },
                icon: 'arrow',
                iconClass: 'rotate-180'
            })
            menuItems.push({
                slot: 'Download App',
                component: 'router-link',
                attributes: {
                    to: { name: 'download' },
                },
                icon: 'arrow',
                iconClass: ''
            });
            menuItems.push({
                slot: 'Activated Licenses',
                component: 'router-link',
                attributes: {
                    to: { name: 'AccountMachinesView' },
                },
                icon: 'gear',
                iconClass: ''
            });
            if(subscribed){
                menuItems.push({
                    slot: 'Subscription',
                    component: 'router-link',
                    attributes: {
                        to: { name: 'AccountSubscriptionView' },
                    },
                    icon: 'heart',
                    iconClass: ''
                },{
                    slot: 'Receipts',
                    component: 'router-link',
                    attributes: {
                        to: { name: 'AccountInvoicesView' },
                    },
                    icon: 'film',
                    iconClass: ''
                })
            }
            if(isAdmin){
                menuItems.push({
                    slot: 'Admin Dashboard',
                    component: 'router-link',
                    attributes: {
                        to: { name: 'AccountAdminDashboard' },
                    },
                    icon: 'gear',
                    iconClass: ''
                })
                menuItems.push({
                    slot: 'Chat',
                    component: 'router-link',
                    attributes: {
                        to: { name: 'AccountAdminChat' },
                    },
                    icon: 'user',
                    iconClass: ''
                })
            }
            return []; // menuItems;
        }
    }
}
