import MultipleModule from './module-templates/multiple';

export default MultipleModule({
  name: 'machines',
  getters: {
    available: (state, getters, rootState, rootGetters) => {
      return rootGetters['user/me'].maximumNumberOfMachines - getters.all.length;
    }
  }
});
