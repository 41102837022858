import axios from 'axios';
import yaml from 'js-yaml';

export default {
  name: 'release',
  namespaced: true,
  state: {
    mac: null,
    windows: null,
    releaseChannel: 'beta'
  },
  mutations:{
    SET_WINDOWS(state, release){
      state.windows = release;
    },
    SET_MAC(state, release){
      state.mac = release;
    },
  },
  actions: {
    async load(context){
      try {
        await axios
          .get(`https://storyfolder-releases.s3.amazonaws.com/${context.state.releaseChannel}.yml`)
          .then(response => {
            context.commit('SET_WINDOWS', yaml.load(response.data))
          })
        await axios
          .get(`https://storyfolder-releases.s3.amazonaws.com/${context.state.releaseChannel}-mac.yml`)
          .then(response => {
              context.commit('SET_MAC', yaml.load(response.data))
          })
        return true;
      }catch(e){
        return false;
      }
    },
    async download(context, platform){
      const release = context.getters[platform];
      if(release){
        window.fbq('track', 'AppDownload', {
          version: release.version,
          platform
        });
        dataLayer.push({
          event: 'AppDownload',
          version: release.version,
          platform
        });
      }
    }
  },
  getters: {
    windows: state => {
      if(state.windows && state.windows.files.length > 0){
        const file = state.windows.files[0];
        return {
          name: 'Windows 10',
          platform: 'windows',
          icon: 'windows',
          minimumRequirements: 'Windows 10 64-bit only',
          file: file.url,
          version: state.windows.version,
          link: `https://storyfolder-releases.s3.amazonaws.com/${file.url}`,
          megabytes: Math.round(file.size / 1024 / 1024)
        }
      }
      return null;
    },
    mac: state => {
      if(state.mac && state.mac.files.length > 0){
        const file = state.mac.files.find(file => file.url.substr(-3) === 'dmg');
        if(file){
          return {
            name: 'macOS',
            platform: 'mac',
            icon: 'apple',
            minimumRequirements: '10.13 or higher',
            file: file.url,
            version: state.mac.version,
            link: `https://storyfolder-releases.s3.amazonaws.com/${file.url}`,
            megabytes: Math.round(file.size / 1024 / 1024)
          }
        }
      }
      return null;
    },
    platform: () => {
      return navigator.platform.toLowerCase().includes('mac') ? 'mac' : 'windows';
    },
    otherPlatform: () => {
      return navigator.platform.toLowerCase().includes('mac') ? 'windows' : 'mac';
    },
    primary: (state, getters) => {
      return getters[getters.platform]
    },
    secondary: (state, getters) => {
      return getters[getters.otherPlatform]
    }
  }
}
