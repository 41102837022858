/* eslint no-async-promise-executor: "off" */
import Vue from 'vue'
import axios from 'axios'

const handle = (promise) => {
  return promise
    .then(data => ([data, undefined]))
    .catch(error => Promise.resolve([undefined, error]))
}

export default function (config) {
  const moduleApi = config.api || config.name

  const module = {
    namespaced: true,
    state: {
      document: {}
    },
    mutations: {
      ON_DEAUTHENTICATE (state) {
        state.document = {}
        if (config.onDeauthenticate) {
          config.onDeauthenticate(state)
        }
      },
      SET (state, object) {
        Vue.set(state, 'document', object)
      },
      UNSET (state) {
        Vue.set(state, 'document', {})
      }
    },
    actions: {
      load: async function (context) {
        return new Promise(async (resolve, reject) => {
          const response = await axios
            .get(`/api/${moduleApi}`)
            .catch(reject)
          if (response && response.data) {
            context.commit('SET', response.data)
            resolve(response.data)
            return response.data;
          } else {
            reject()
          }
        })
      },
      create: async function (context, object) {
        return new Promise(async (resolve, reject) => {
          if (config.beforeSave) {
            const [success, error] = await handle(config.beforeSave(context, object))
            success ? success : success;
            if (error) {
              reject(error)
              return
            }
          }
          const response = await axios
            .post(`/api/${moduleApi}`, object)
            .catch(reject)
          if (response && response.data) {
            context.commit('SET', response.data)
            resolve(response.data)
          } else {
            reject()
          }
        })
      },
      update: async function (context, object) {
        return new Promise(async (resolve, reject) => {
          if (config.beforeSave) {
            const [success, error] = await handle(config.beforeSave(context, object))
            success ? success : success;
            if (error) {
              reject(error)
              return
            }
          }
          const response = await axios
            .put(
              `/api/${moduleApi}`,
              object
            )
            .catch(reject)
          if (response && response.data) {
            context.commit('SET', response.data)
            resolve(response.data)
          } else {
            reject()
          }
        })
      },
      delete: async function (context) {
        return new Promise(async (resolve, reject) => {
          const response = await axios
            .delete(`/api/${moduleApi}`)
            .catch(reject)
          if (response && response.data) {
            context.commit('UNSET')
            resolve()
          } else {
            reject()
          }
        })
      }
    },
    getters: {
      get: (state) => {
        if(state.document && state.document.id){
          return state.document
        }
        return null;
      }
    }
  }

  Object.assign(module.state, config.state || {})
  Object.assign(module.mutations, config.mutations || {})
  Object.assign(module.actions, config.actions || {})
  Object.assign(module.getters, config.getters || {})

  return module
}
