import axios from 'axios';

import SingularModule from './module-templates/singular';

export default SingularModule({
  name: 'paymentMethod',
  api: 'payment-methods',
  actions: {
    // THIS IS THE ONE FOR MODIFYING PAYMENT METHODS
    async getCheckoutUrl(){
      const response = await axios.get(`/api/payment-methods/checkout-url`);
      if(response && response.data && response.data.url){
        return response.data.url;
      }
      return new Error('Unknown error')
    },
    async create(context, sessionId){
      const response = axios.post(`/api/payment-methods`,{ sessionId });
      if(response && response.data){
        await context.dispatch('load')
      }
    }
  }
});
