import MultipleModule from './module-templates/multiple';

export default MultipleModule({
    name: 'plans',
    getters: {
        withCoupon(state, getters, rootState, rootGetters) {
            const coupon = rootGetters['coupon/get'];
            return getters.all.map((plan) => {
                const newPlan = Object.assign(
                    { ...plan },
                    {
                        percentOff: {
                            year: 0,
                            month: 0
                        }
                    }
                );
                if(coupon){
                    const {metadata} = coupon;
                    const {planIds, intervals} = metadata;
                    const percentOff = rootGetters['coupon/percentOff'];
                    if(planIds.includes(plan._id)){
                        newPlan.percentOff = {
                            year: intervals.includes('year') ? percentOff : 0,
                            month: intervals.includes('month') ? percentOff : 0
                        }
                    }
                }

                return newPlan;
            }).sort((a, b) => a.position - b.position).reverse()
        }
    }
});
