import axios from "axios";
import SingularModule from './module-templates/singular';
export default SingularModule({
    name: 'coupons',
    state: {
        countdownInterval: null,
        countdown: null,
        document: {}
    },
    mutations: {
        SET_COUNTDOWN(state, countdown) {
            state.countdown = countdown;
        },
        SET_COUNTDOWN_INTERVAL(state, interval) {
            state.countdownInterval = interval;
        },
        SET_EXPIRED(state){
            if(state.coundtownInterval){
                clearInterval(state.countdownInterval);
            }
            state.countdownInterval = null;
            state.countdown = null;
            state.document = {};
        }
    },
    actions: {
        load: async function (context, userIdOrEmail = null) {
            if(context.rootGetters['user/isAuthenticated']){
                userIdOrEmail = context.rootGetters['user/me']._id;
            }else if(context.rootState.user.user?.email){
                userIdOrEmail = context.rootState.user.user.email;
            }
            try{
                const response = await axios.get(`/api/coupons/${userIdOrEmail || ''}`)
                if (response && response.data) {
                    context.commit('SET', response.data)
                    return response.data;
                }
            } catch(e){
                console.error('Error loading coupons for user:', e);
                return null;
            }
        },
        async onCouponExpire({ commit, dispatch }) {
            // Delete the coupon
            commit('SET_EXPIRED');
            // Ensure countdown is stopped
            await dispatch('stopCountdown');
            // See if we have any new coupons available
            await dispatch('load');
        },
        startCountdown({ state, commit, getters, dispatch }) {
            const updateCountdown = () => {


                const expires = getters.expires;
                if(!expires){
                    // this doesn't expire
                    return dispatch('stopCountdown');
                }
                const expiresOn = new Date(state.document.expiresOn * 1000).getTime(); // Convert to milliseconds
                let difference = expiresOn - new Date().getTime();
                const isExpired = difference < 0;

                // if it's expired, cancel countdown
                if(isExpired){
                    return dispatch('onCouponExpire');
                }

                const minute = 60 * 1000;
                const hour = 60 * minute;
                const day = 24 * hour;
                const month = 30 * day; // Approximation for month

                let value = null;
                if (difference >= month) {
                    const months = Math.floor(difference / month);
                    value = `${months} month${months !== 1 ? 's' : ''}`;
                } else if (difference >= (day * 3)) {// 72 hours
                    const days = Math.floor(difference / day);
                    value = `${days} day${days !== 1 ? 's' : ''}`;
                } else if (difference >= hour) {
                    const hours = Math.floor(difference / hour);
                    value = `${hours} hour${hours !== 1 ? 's' : ''}`;
                } else if(difference >= minute){
                    const minutes = Math.floor(difference / minute);
                    value = `${minutes} minute${minutes !== 1 ? 's' : ''}`;
                } else if(difference > 0){
                    const seconds = Math.floor(difference / 1000);
                    value = `${seconds} second${seconds !== 1 ? 's' : ''}`;
                }
                commit('SET_COUNTDOWN', value);
            };

            updateCountdown(); // Update immediately
            clearInterval(state.countdownInterval);
            const interval = setInterval(updateCountdown, 1000);
            commit('SET_COUNTDOWN_INTERVAL', interval);
        },
        stopCountdown({ state, commit }) {
            clearInterval(state.countdownInterval);
            commit('SET_COUNTDOWN', null);
            commit('SET_COUNTDOWN_INTERVAL', null);
        }
    },
    getters: {
        hasCoupon: state => {
            return !!state.document?.id;
        },
        couponIsAvailable: (state, getters, rootState, rootGetters) => {
            return getters.hasCoupon && !rootGetters['subscriptions/isSubscribed'];
        },
        percentOff: state => {
            return Number(state.document?.percent_off_precise || 0);
        },
        metadata: state => {
            return state.document?.metadata || {};
        },
        expires: state => {
            return state.document?.expiresOn || false;
        },
        couponDescription: (state, getters) => {
            const {name, description} = getters.metadata;
            return description || name
        }
    }
});
