/* eslint no-async-promise-executor: "off" */
import MultipleModule from './module-templates/multiple';
import axios from "axios";

export default MultipleModule({
  name: 'storyboards',
  actions: {
    readSecurely: async function (context, {id, password}) {
      return new Promise(async (resolve, reject) => {
        const response = await axios
            .get(`/api/storyboards/${id}?password=${password}`)
            .catch(reject)
        if (response && response.data) {
          context.commit('SET', response.data)
          resolve(response.data)
        } else {
          reject()
        }
      })
    },
  }
});
