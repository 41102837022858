import MultipleModule from './module-templates/multiple';
import axios from 'axios';

export default MultipleModule({
  name: 'videos',
  actions: {
    getDataForUrl(context, url){
      return axios.get(`/api/videos/data-for-url?url=${url}`)
    }
  }
});