module.exports = {
    name: "testimonials",
    namespaced: true,
    state: {
        items: [
            {
                "name": "Emily Patel",
                "review": "Absolutely loving how it pinpoints those 'aha' moments in videos!",
                "headline": "Nails those 'aha' moments!"
            },
            {
                "name": "Jacob Johnson",
                "review": "It's like having a knowledgeable buddy answer my questions during a video binge. Super handy!",
                "headline": "Like a know-it-all buddy!"
            },
            {
                "name": "Sophia Lee",
                "review": "The precision in digging out the nitty-gritty from videos is just spot-on. I'm learning so much!",
                "headline": "Deep learning tool"
            },
            {
                "name": "Oliver Martinez",
                "review": "Way easier taking lecture notes now. It's a study booster for sure.",
                "headline": "My study booster"
            },
            {
                "name": "William Robinson",
                "review": "YouChat is perfect for my learning style. It's like it knows exactly what I need.",
                "headline": "Tailored just for me!"
            },
            {
                "name": "James Rodriguez",
                "review": "YouChat's versatility with different video types amazes me every time. It's a one-size-fits-all!",
                "headline": "Versatile and adaptive!"
            },
            {
                "name": "Charlotte Martinez",
                "review": "'Explain this like Im 5' is my new favorite prompt. Learning has never been this easy!",
                "headline": "Complex made simple"
            },
            {
                "name": "Amelia Davis",
                "review": "The depth of analysis and insight into videos is mind-blowing. It's like having an expert in your pocket!",
                "headline": "Pocket-sized expert"
            },
            {
                "name": "Lucas Lopez",
                "review": "I love making quizzes for my students! It's fun, engaging, and oh-so-educational!",
                "headline": "Quizzes that WORK!"
            },
            {
                "name": "Ella Young",
                "review": "Linking similar topics in videos has deepened my understanding in ways I didn't expect. A MAJOR eye-opener!",
                "headline": "Deepens understanding!"
            },
            {
                "name": "Jackson Hall",
                "review": "My son has ADHD and has been loving YouChat for his online classes. It's a game-changer!",
                "headline": "ADHD Friendly!"
            },
            {
                "name": "Avery Allen",
                "review": "The blend of visual and text explanations is just what I needed. It's learning made easy and fun!",
                "headline": "Perfect blend of learning!"
            },
            {
                "name": "Grace King",
                "review": "I'm thrilled with the variety of topics I can dive into. It's like an all-you-can-learn buffet!",
                "headline": "Learning buffet!"
            },
            {
                "name": "Ryan Green",
                "review": "Getting the gist of videos in a snap has saved me tons of time. It's like a mini crash course every time!",
                "headline": "Crash course in a snap!"
            },
            {
                "name": "David Nelson",
                "review": "Overcoming language barriers in videos is a breeze with YouChat. It's like a universal translator for learning!",
                "headline": "Universal learning translator!"
            },
            {
                "name": "Carter Mitchell",
                "review": "The way YouChat adds context and background to videos is a total game-changer for me. I'm absorbing so much more!",
                "headline": "Contextual learning genius!"
            },

            {
                "name": "Alexa Rivera",
                "review": "As a content creator, YouChat has been amazing for learning new editing techniques. It's like having a personal mentor guiding me through each step!",
                "headline": "Editing Skills Mentor"
            },
            {
                "name": "Jordan Kim",
                "review": "Discovering innovative content creation strategies through YouChat has been transformative for my marketing campaigns. It's a treasure trove of ideas!",
                "headline": "Content strategy treasure trove"
            },
            {
                "name": "Tyler Brooks",
                "review": "As a video producer, this tool has been invaluable for mastering advanced filming techniques. It's like attending a masterclass in every session!",
                "headline": "Masterclass in filming techniques"
            },
            {
                "name": "Riley Thompson",
                "review": "YouChat makes our video agency way more effecient. Especially when it comes to client discovery.",
                "headline": "Video Agency Efficiency"
            },
            {
                "name": "Casey Lee",
                "review": "Learning cutting-edge animation and graphic design through YouChat has significantly upped my content creation game. A must-have for all digital creators!",
                "headline": "Content Creator's Must-Have"
            },
            {
                "name": "Morgan Sanchez",
                "review": "I've been able to explore new genres and styles in my video marketing, thanks to the diverse learning resources YouChat offers.",
                "headline": "Marketing & Advertising Goldmine"
            },
            {
                "name": "Jamie Patel",
                "review": "As a filmmaker, the insights I've gained on narrative techniques and cinematography from YouChat are invaluable. It's been a game-changer for my projects!",
                "headline": "Game-changer for filmmakers"
            },
            {
                "name": "Taylor Murphy",
                "review": "The app has been a fantastic tool for brainstorming and developing unique content ideas. It's like having a brainstorming partner available 24/7!",
                "headline": "Content Brainstorming made Easy"
            }
        ]
    },
    getters: {
        random: state => count => {
            return state.items.sort(() => Math.random() - Math.random()).slice(0, count);
        }
    }
}