import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
axios.defaults.withCredentials = true
Vue.use(Vuex)

// Import vuex modules
import user from './user';
import assistants from './assistants';
import release from './release';
import accounts from './accounts'
import paymentMethods from './paymentMethods'
import subscriptions from './subscriptions'
import machines from './machines'
import invoices from './invoices'
import articles from './articles';
import storyboards from './storyboards';
import navigation from "@/store/navigation";
import plans from "@/store/plans";
import coupon from "@/store/coupon";
import videos from "@/store/videos";
import testimonials from "@/store/testimonials";
// import chatMessages from "@/store/chat-messages.ts";

import VuexPersist from 'vuex-persist'
const vuexLocalStorage = new VuexPersist({
  key: 'storyfolder',
  storage: window.localStorage,
  modules: ['user','release'],
  reducer: (state) => ({
    release: state.release,
    user: {
      user: {
        email: state.user.user.email,
        tags: state.user.user.tags
      }
    }
  })
})

const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    release,
    user,
    accounts,
    subscriptions,
    paymentMethods,
    invoices,
    machines,
    articles,
    storyboards,
    navigation,
    assistants,
    plans,
    coupon,
    testimonials,
    // chatMessages,
    videos
  }
})

export default store
